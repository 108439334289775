import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"

const FAQPage = () => {
  return (
    <Layout>
      <SEO title="FAQ" />
      <div className="page-headline mt-5 about-bg">
        <div className="container">
          <div className="section-heading text-center ">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <h1 className="font-weight-bold text-uppercase text-white mb-4">
                  <strong>FREQUENTLY ASKED QUESTIONS</strong>
                </h1>
                <p className="text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-section" id="">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="container">
                <div className="content text-center">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="col">
                        <div class="tabs text-left">
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd1"
                              name="rd"
                            />
                            <label class="tab-label" for="rd1">
                              What is CBD?
                            </label>
                            <div class="tab-content">
                              CBD is also known as Cannabidiol, which is a
                              substance extracted from hemp, which is a commonly
                              used term for strains of the cannabis sativa
                              plant. Our CBD products are infused into a variety
                              of products, spanning from edibles, to pet, health
                              and beauty and wellness products.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd2"
                              name="rd"
                            />
                            <label class="tab-label" for="rd2">
                              Do your products contain THC?
                            </label>
                            <div class="tab-content">
                              No. All of our products contain 0% THC.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd3"
                              name="rd"
                            />
                            <label class="tab-label" for="rd3">
                              Will CBD show up on a drug test?
                            </label>
                            <div class="tab-content">
                              While all of our products contain 0% THC, we can
                              never guarantee that CBD will not show up in any
                              drug tests, as many drug tests differ.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd4"
                              name="rd"
                            />
                            <label class="tab-label" for="rd4">
                              Should I feel the effects of CBD right away?
                            </label>
                            <div class="tab-content">
                              Unlike THC, which produces an almost immediate
                              ‘high’ feeling, CBD is different, and all users
                              experience its effects differently. Depending on
                              the amount of CBD infused into the product you are
                              using, your body mass, metabolism etc. CBD will
                              affect everybody in a different way. We suggest
                              trying a range of products in order to see which
                              CBD effect is right for you.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd5"
                              name="rd"
                            />
                            <label class="tab-label" for="rd5">
                              Is CBD right for me?
                            </label>
                            <div class="tab-content">
                              For most people seeking relief from pain, nausea,
                              anxiety, depression, inflammation, and other
                              symptoms, CBD is typically completely safe. If
                              there are any doubts, please consult your
                              physician.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd6"
                              name="rd"
                            />
                            <label class="tab-label" for="rd6">
                              Is CBD legal?
                            </label>
                            <div class="tab-content">
                              Yes. CBD is legal in all 50 states.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd7"
                              name="rd"
                            />
                            <label class="tab-label" for="rd7">
                              Is Hemp different from Marijuana?
                            </label>
                            <div class="tab-content">
                              Yes. Notably, the most important difference is,
                              Hemp will not get you ‘high,’ nor is it intended
                              to do so. Hemp, no matter what strain, is very
                              commonly-used in a variety of products and contain
                              very low levels of tetrahydrocannabinol (THC).
                              None of our products contain any THC.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd8"
                              name="rd"
                            />
                            <label class="tab-label" for="rd8">
                              Has my order shipped?
                            </label>
                            <div class="tab-content">
                              At the time of placing your order, you will
                              receive an email with a tracking number from the
                              courier it was sent by. Sometimes couriers can
                              experience delays, so please be sure to contact
                              the courier first and open a claim with them. If
                              unsuccessful, please contact us at 833-954-8387 or
                              email us at help@heavenlycandy.com and will do our
                              best to assist you further.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd9"
                              name="rd"
                            />
                            <label class="tab-label" for="rd9">
                              Can I track my package?
                            </label>
                            <div class="tab-content">
                              Yes. Please use the tracking number from the
                              shipping confirmation email you received to track
                              your package.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd10"
                              name="rd"
                            />
                            <label class="tab-label" for="rd10">
                              Can I purchase wholesale?
                            </label>
                            <div class="tab-content">
                              Yes, absolutely. Our wholesale business is
                              booming, and we would love you to be a part of our
                              business. Our wholesalers include convenience
                              stores, farmers market vendors and even ice cream
                              stores, our products are great for any market.
                              Contact us at help@heavenlycandy.com or fill out
                              an online contact form{" "}
                              <Link className="text-purple" href="/contact">
                                here
                              </Link>
                              . Even if you’re unsure or just have a question,
                              why not give us a try, risk free!
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd11"
                              name="rd"
                            />
                            <label class="tab-label" for="rd11">
                              Do you offer discounts?
                            </label>
                            <div class="tab-content">
                              While we are a young company, we still love
                              serving our customers and want them to try our
                              products to see their benefits. We send out
                              discounts often, so keep an eye on our Instagram,
                              Facebook and Twitter accounts! We know you will
                              not be disappointed.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd12"
                              name="rd"
                            />
                            <label class="tab-label" for="rd12">
                              Can I cancel my order?
                            </label>
                            <div class="tab-content">
                              We aim to ship all of our orders out quickly, so
                              your order may already be in transit. If you wish
                              to cancel your order give us a call at
                              833-954-8387 or email us at
                              help@heavenlycandy.com. While we cannot guarantee
                              that we will be able to cancel your order in time,
                              we will certainly do our best. If the order is in
                              transit already, then you will have to send the
                              product back to receive a refund.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd13"
                              name="rd"
                            />
                            <label class="tab-label" for="rd13">
                              What is your returns policy?
                            </label>
                            <div class="tab-content">
                              We accept turns on all unopened items within 20
                              days of purchase. The item must be in the same
                              condition in which it was shipped. For help with
                              your return, please email help@heavely-candy.com,
                              providing us with your name and order number. You
                              must pay the shipping cost for all return items.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd14"
                              name="rd"
                            />
                            <label class="tab-label" for="rd14">
                              Do you offer refunds?
                            </label>
                            <div class="tab-content">
                              Yes, we offer refunds but not in all cases. Once
                              your return item is received you will receive an
                              email notifying you that it has arrived at our
                              facility and will be inspected by a member of our
                              team. You will then receive a follow-up email
                              notifying you if the return has been approved or
                              rejected. If approved, your refund will be process
                              via your original payment method with 7-14
                              business days. Please contact us for further
                              details on returns or refunds.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd15"
                              name="rd"
                            />
                            <label class="tab-label" for="rd15">
                              Can I find your products in stores?
                            </label>
                            <div class="tab-content">
                              Yes. Our products are available in thousands of
                              stores both in the United States and Worldwide. We
                              will be adding a ‘Store Locator’ soon so stay
                              tuned!
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd16"
                              name="rd"
                            />
                            <label class="tab-label" for="rd16">
                              Do you ship worldwide?
                            </label>
                            <div class="tab-content">
                              Yes. We ship to all 50 States, the UK and
                              Worldwide.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd17"
                              name="rd"
                            />
                            <label class="tab-label" for="rd17">
                              What are your shipping costs?
                            </label>
                            <div class="tab-content">
                              Shipping to the United Kingdom and United States
                              are free for all orders. All other orders are
                              subject to calculated shipping rates. However,
                              since we do have fulfilment hubs in both the
                              States and the UK, we are able to offer very low
                              shipping costs, passing on the savings to you.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd18"
                              name="rd"
                            />
                            <label class="tab-label" for="rd18">
                              Who do I call if I have a question?
                            </label>
                            <div class="tab-content">
                              If you have a question about our products or your
                              order, please give us a call at 833-954-8387 or
                              email us at help@heavenlycandy.com. If you would
                              like to reach us from outside the US by phone,
                              please find us on WhatsApp (number here) and we
                              will get back to you as soon as possible.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd19"
                              name="rd"
                            />
                            <label class="tab-label" for="rd19">
                              How can I contact you?
                            </label>
                            <div class="tab-content">
                              We can be contacted by phone (833) 954-8387, by
                              email at help@heavenly-candy.com and feel free to
                              send us a message or call us on WhatsApp using the
                              number +1 (603) 568-2860.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd99"
                              name="rd"
                            />
                            <label for="rd99" class="tab-close">
                              Close others &times;
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default FAQPage
